import React from "react"
import TermsNigeria from "../../../components/body/pages/terms/TermsNigeria"
import NoheaderFooter from "../../../components/Noheaderfooter"
import SEO from "../../../components/seo"

const TermsNigeriaPage = () => (
  <NoheaderFooter>
    <SEO
      canonical={"https://kuda.com/legal/ng"}
      title="Terms & Conditions | Kuda | The Money App for Africans"
    />
    <TermsNigeria />
  </NoheaderFooter>
)

export default TermsNigeriaPage
